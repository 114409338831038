body {
  margin: 0;
  font-family: "Muli", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:link, a:focus, a:hover{
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-main {
  color: #2e86c1;
}

.text-pre {
  color: #f1c40f;
}
